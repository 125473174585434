<template>
  <v-content>
    <toolbar></toolbar>
    <v-app
      id="dashboard-view"
      light>      
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-app>
  </v-content>
</template>

<script>

import toolbar from '@/components/Toolbar';

export default {
  name: 'DashboardView',
  components:{
    toolbar
  }
}
</script>